import * as React from 'react';
import cn from 'classnames';
import css from './BoxDesignHero.scss';
import Grid from 'styleguide/components/Grid/Grid';
import { H1, H2 } from 'styleguide/components/Heading';
import IconCheckmark from 'styleguide/icons/IconCheckmark';
import BoxDesignForm from 'bundles/App/pages/service/BoxDesign/BoxDesignForm/BoxDesignForm';

import heroImg from 'assets/images/business-card-stack.png';

interface Props {
  a?: string;
}

interface TextWithIconProps {
  children: string;
  icon: React.ReactNode;
  containerClass?: string;
  iconWrapperClass?: string;
  contentWrapperClass?: string;
}

const TextWithIcon = ({
  children,
  icon,
  containerClass,
  iconWrapperClass,
  contentWrapperClass,
}: TextWithIconProps) => (
  <div className={cn(css.textWithIcon, containerClass)}>
    {!!icon && <div className={cn(css.iconWrapper, iconWrapperClass)}>{icon}</div>}
    <div className={cn(css.textWrapper, contentWrapperClass)}>{children}</div>
  </div>
);

const checkIcon = () => <IconCheckmark color="white" size="sm" />;

const BoxDesignHero = (props: Props) => (
  <Grid.Container className={cn(css.container)}>
    <Grid>
      <Grid.Row>
        <Grid.Col lg={6} className={css.heroText}>
          <H1 className={cn(css.heroText, css.heroLead)}>GRAPHIC DESIGN SERVICES</H1>
          <H2 className={cn(css.heroText, css.heroMain)}>
            Get free design services with your order of shipping boxes.
          </H2>
          <H2 className={cn(css.heroText, css.heroTag)}>
            Create an exciting unboxing experience without becoming a Photoshop master.
          </H2>
          <div className={cn(css.heroText, css.heroList)}>
            <TextWithIcon icon={checkIcon()}>Custom & unique, made to order designs.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>$249 value, completely free.</TextWithIcon>
            <TextWithIcon icon={checkIcon()}>Free with your order of 100 boxes or more.</TextWithIcon>
          </div>
        </Grid.Col>
        <Grid.Col className={cn(css.imageColumn)} style={{ backgroundImage: `url(${heroImg})` }} lg={6}>
          <div className={css.formContainer}>
            <BoxDesignForm className={css.formContainer} {...props} />
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default BoxDesignHero;
