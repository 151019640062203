import { StepProps } from 'styleguide/components/InformationWithSteps/InformationWithSteps';
import {
  IconChooseDesign,
  IconSelectTemplate,
  IconDesign,
  IconOne,
  IconTwo,
  IconThree,
} from 'styleguide/icons';
import img1 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_1.png';
import img2 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_2.png';
import img3 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_3.png';
import img4 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_4.png';
import img5 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_5.png';
import img6 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_6.png';
import img7 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_7.png';
import img8 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_8.png';
import img9 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_9.png';
import img10 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_10.png';
import img11 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_11.png';
import img12 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_12.png';
import img13 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_13.png';
import img14 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_14.png';
import img15 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_15.png';
import img16 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_16.png';
import img17 from 'assets/images/BoxDesign/Shipping_Boxes_Photo_17.png';
import { Faq } from 'bundles/App/pages/HelpCenter/types';

export const steps: StepProps[] = [
  {
    Icon: IconChooseDesign,
    StepNumberIcon: IconOne,
    title: 'Describe',
    description: 'Submit a request to get in touch with our design team.',
  },
  {
    Icon: IconSelectTemplate,
    StepNumberIcon: IconTwo,
    title: 'View',
    description: "We'll reach out to learn more about your business, brand, and vision.",
  },
  {
    Icon: IconDesign,
    StepNumberIcon: IconThree,
    title: 'Relax',
    description: "We'll start working on your designs and send you a few drafts before we send print files.",
  },
];

export const images = [
  {
    url: img1,
    alt: '',
    title: '',
  },
  {
    url: img2,
    alt: '',
    title: '',
  },
  {
    url: img3,
    alt: '',
    title: '',
  },
  {
    url: img4,
    alt: '',
    title: '',
  },
  {
    url: img5,
    alt: '',
    title: '',
  },
  {
    url: img6,
    alt: '',
    title: '',
  },
  {
    url: img7,
    alt: '',
    title: '',
  },
  {
    url: img8,
    alt: '',
    title: '',
  },
  {
    url: img9,
    alt: '',
    title: '',
  },
  {
    url: img10,
    alt: '',
    title: '',
  },
  {
    url: img11,
    alt: '',
    title: '',
  },
  {
    url: img12,
    alt: '',
    title: '',
  },
  {
    url: img13,
    alt: '',
    title: '',
  },
  {
    url: img14,
    alt: '',
    title: '',
  },
  {
    url: img15,
    alt: '',
    title: '',
  },
  {
    url: img16,
    alt: '',
    title: '',
  },
  {
    url: img17,
    alt: '',
    title: '',
  },
];

export const faqs: Faq[] = [
  {
    question: 'How do I qualify for free box design services?',
    answer:
      'Box design services are free with your purchase of 100 or more shipping boxes with Printivity. We’ll create a design and release the final design files after you’ve placed your shipping box order. This promotion is only valid until October 31, 2021. Limited to a single redemption per business.',
  },
  {
    question: 'How long does the design process take?',
    answer:
      'About 2 business days from when we’ve received your full design request. We’ll need things like logo files, other brand assets, and information about your product or business to get started on your design project.',
  },
  {
    question: 'Who are your designers?',
    answer:
      'Our designers are part of the Printivity team based in San Diego, CA. We’re a passionate team of creative directors, graphic designers, packaging specialists, and print artists.',
  },
  {
    question: 'How does the design process work?',
    answer:
      '<a href="#">Submit a design request</a> on this page. We’ll reach out to request additional files like your logo, and may ask some more questions about your business, product, or service. Once we’ve received everything we need to get started, we’ll send a design proof to your inbox within about 2 business days. Our design team can adjust and tweak the design as necessary, but we’re limited to single revision. If you have a specific idea for your box design, please create detailed design notes to help communicate your vision to our design team. After you’ve approved the design, we’ll create a packaged final design file which will be released once you’ve placed your shipping box order on Printivity.',
  },
  {
    question: 'What if I don’t like my box design?',
    answer:
      'No fees, zero risk, no problem—we’re confident we can spin up a design that will work for your business. In the unlikely case that you’re unhappy with your design, you won’t be charged any design fees.',
  },
  {
    question: 'Do I have to pay anything upfront to request design services?',
    answer:
      'There are no down payments or fees—we’ll release your final design files after your <a href="/boxes/shipping-boxes">shipping box</a> purchase.',
  },
  {
    question: 'What if I already have a box design?',
    answer:
      "That's great! Simply visit our <a href='/boxes/shipping-boxes'>shipping boxes product page</a>, add an item to your cart, and upload your design to us. Our prepress team will double check your files and send you a digital proof to ensure the box will look exactly as you envision.",
  },
];
