import * as React from 'react';
import cn from 'classnames';
import css from './BoxDesignForm.scss';
import { object, string } from 'yup';
import { Field, Form, Formik, FormikErrors, FormikValues } from 'formik';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import Input from 'styleguide/components/Formik/Input/Input';
import Captcha from 'bundles/App/pages/ContactUsPage/Captcha';
import Button from 'styleguide/components/Button/Button';
import { submitForm } from 'api/contactUs';
import { Status } from 'libs/utils/api/types';
import { phoneRegex } from 'utils/schema';
import { RouteComponentProps, withRouter } from 'react-router';
import A from 'styleguide/components/Links/A';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';

interface FormProps extends RouteComponentProps {
  className?: string;
}

const designRequestSchema = object().shape({
  userInput: object().shape({
    name: string().required('Name is required'),
    email: string().email('Please enter a valid email.').required('Email is required'),
    phoneNumber: string()
      .required('Phone number is required')
      .matches(phoneRegex, 'Phone number is not valid'),
  }),
  shippingBoxDesignRequest: object().shape({
    businessName: string(),
    comments: string(),
  }),
});

const BoxDesignForm = ({ className, history }: FormProps) => {
  const [recaptchaResponse, setRecaptchaResponse] = React.useState<string>(null);
  const onSubmit = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    submitForm({ ...values, recaptchaResponse, formType: 'shippingBoxDesignRequest' }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        history.push('/contact-us/success');
      }
    });
  };

  return (
    <>
      <A className={css.anchorOffset} href="#" color="none" underline="none" name="contact-us" />

      <Formik
        initialValues={{
          name: '',
          email: '',
          phoneNumber: '',
          businessName: '',
          comments: '',
        }}
        onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
          onSubmit(values, setStatus, setSubmitting, setErrors);
        }}
        validationSchema={designRequestSchema}
      >
        {() => (
          <div className={cn(className, css.formContainer)}>
            <Form className={css.form}>
              <div className={cn(css.fieldContainer, css.formHeader)}>
                Get more info on our box design services!
              </div>
              <FieldWrapper
                className={cn(css.fieldContainer)}
                Input={<Field name="userInput.name" inPlaceError component={Input} label="Full Name" />}
              />
              <FieldWrapper
                className={cn(css.fieldContainer)}
                Input={
                  <Field name="userInput.email" type="email" inPlaceError component={Input} label="Email" />
                }
              />
              <FieldWrapper
                className={cn(css.fieldContainer)}
                Input={
                  <Field name="userInput.phoneNumber" inPlaceError component={Input} label="Phone Number" />
                }
              />
              <div className={cn(css.fieldContainer)}>
                <Field
                  name="shippingBoxDesignRequest.businessName"
                  inPlaceError
                  rows={5}
                  component={Input}
                  label="Business Name"
                />
              </div>
              <div className={cn(css.fieldContainer)}>
                <FieldWrapper
                  className={cn(css.fieldContainer)}
                  Input={
                    <Field
                      name="shippingBoxDesignRequest.comments"
                      inPlaceError
                      rows={5}
                      component={Textarea}
                      label="Project Details"
                    />
                  }
                />
              </div>
              <Captcha verifyCallback={response => setRecaptchaResponse(response)} />
              <Button color="orange" type="submit">
                Submit Request
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default withRouter(BoxDesignForm);
