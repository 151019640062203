import * as React from 'react';
import css from './BoxDesign.scss';
import Meta from 'styleguide/components/Meta/Meta';
import Footer from 'styleguide/components/Footer/Footer';
import Carousel from 'nuka-carousel';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import BoxDesignHero from 'bundles/App/pages/service/BoxDesign/BoxDesignHero/BoxDesignHero';
import InformationWithSteps from 'styleguide/components/InformationWithSteps/InformationWithSteps';
import Image from 'styleguide/components/Image/Image';
import Grid from 'styleguide/components/Grid/Grid';
import { H2 } from 'styleguide/components/Heading';
import { IconArrowLeft, IconArrowRight } from 'styleguide/icons';
import CallToActionRow from 'bundles/App/pages/CategoryPage/row/CallToActionRow';
import FAQ from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/FAQ/FAQ';
import AdditionalInfo from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/AdditionalInfo';
import colors from 'styleguide/styles/colors';
import { faqs, images, steps } from 'bundles/App/pages/service/BoxDesign/data';

// const myclass:string = ''
const BoxDesign = props => (
  <>
    <Meta
      title="Free Custom Box Design Services for Your Business | Printivity"
      canonical="https://www.printivity.com/box-design"
      description="Get free box design services with your purchase of shipping boxes with Printivity. Our graphic designers will create a custom, unique design for your business."
      faqs={faqs}
    />
    <BoxDesignHero {...props} />
    <Grid.Container className={css.carouselContainer}>
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <H2 className={css.heading}>Check out our latest work</H2>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className={css.carouselInternalSpacing}>
          <Grid.Col lg={3} hiddenLg="down" />
          <Grid.Col lg={6} className={css.carousel}>
            <Carousel
              autoplay
              autoplayInterval={3000}
              wrapAround
              renderCenterLeftControls={({ previousSlide }) => (
                <IconArrowLeft onClick={previousSlide} size="xxl" color="blue" />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <IconArrowRight onClick={nextSlide} size="xxl" color="blue" />
              )}
              defaultControlsConfig={{
                pagingDotsStyle: {
                  fill: colors.blue,
                },
                pagingDotsContainerClassName: css.dotsPlacement,
              }}
            >
              {images.map((image, i) => (
                <Image
                  key={`${image.alt}_${i}`}
                  className={css.carouselImage}
                  width={584}
                  height={584}
                  alt={image.alt}
                  title={image.title}
                  url={image.url}
                />
              ))}
            </Carousel>
          </Grid.Col>
          <Grid.Col lg={3} hiddenLg="down" />
        </Grid.Row>
      </Grid>
    </Grid.Container>
    <CallToActionRow
      callToAction={{
        type: 'Comfy::Cms::Page::Row::CallToAction',
        title:
          "I love my box design! The design team dreamt up something really cool and creative. Plus it's free! Amazing!",
        subtextLine1: 'Rachel T.',
        subtextLine2: 'Business Owner',
      }}
    />

    <InformationWithSteps heading="How Free Design Works" steps={steps} />
    <div className={css.contactButtonContainer}>
      <ButtonAsNativeLink
        buttonType="anchor"
        target="#contact-us"
        color="orange"
        className={css.contactButton}
      >
        Contact Us
      </ButtonAsNativeLink>
    </div>
    <AdditionalInfo FAQ={<FAQ questions={faqs} />} />
    <Footer />
  </>
);

export default BoxDesign;
